import React, { FC, useState, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import {
  createMarkup,
  getBackgroundColor,
  getResponsiveType,
} from '../../utils';
import { Button } from '../button';
import { Container } from '../container';
import { Close, Menu } from '../icon';
import { LinkLabel } from '../linkLabel';
import { LinkTo } from '../linkTo/LinkTo';
import { Logo } from '../Logo/Logo';
import { Navigation } from '../navigation/Navigation';
import { ModalContext } from '../modal/ModalProvider';
import { FormRenderer } from '../componentRenderer/FormRenderer';

// Interface

export interface HeaderProps {}

// Styles

const StyledHeader = styled.header`
  ${({ theme: { media } }) => css`
    width: 100%;

    @media (min-width: ${media.large}) {
      display: flex;
      flex-wrap: wrap;
    }
  `};
`;

const StyledContainer = styled(Container)`
  ${({ theme: { media } }) => css`
    width: 100%;
    padding: 0;

    @media (min-width: ${media.large}) {
      display: flex;
      flex-direction: row;
    }
  `};
`;

const StyledTopBarContainer = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    ${getBackgroundColor('neutralWhite')};
    padding: ${space.xSmall} ${space.small};

    @media (min-width: ${media.large}) {
      padding: ${space.small} 0;
    }
  `};
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { space, layers, media } }) => css`
    margin-right: ${space.small};
    max-width: 180px;

    @media (min-width: ${media.large}) {
      z-index: ${layers.navigationTitle};
      min-width: 120px;
      max-width: 320px;
    }
  `}
`;

const StyledButtons = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    gap: ${space.xSmall};
  `};
`;

const StyledMenuButton = styled(Button)`
  ${({ theme: { colors, layers, media } }) => css`
    min-width: 24px;

    &:hover {
      background-color: transparent;
      color: ${colors.brandOne100};
    }

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const StyledPrimaryActionBar = styled.div`
  ${({ theme: { space, colors } }) => css`
    width: 100%;
    color: ${colors.neutralWhite};
    ${getBackgroundColor('neutralGrey')};
    padding: ${space.xSmall} 0;
  `};
`;

const StyledActionContainer = styled(Container)`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: ${media.large}) {
      flex-wrap: nowrap;
      align-items: center;
      flex-basis: 100;
      gap: ${space.xxSmall};
      justify-content: space-between;
    }
  `};
`;

const StyledLinkLabelTel = styled(LinkLabel)`
  ${() => css`
    ${getResponsiveType('headingSmall')};
    font-weight: 900;
    text-decoration: none;
  `};
`;

const CallBackLink = ({
  label,
  data,
  ...rest
}: {
  label: string;
  data: object;
  inverse: boolean;
}) => {
  const { open, hide } = useContext(ModalContext);
  const { form } = data?.header?.headerForm?.[0]?.block.components[0] || {};
  const { formGuid } = data?.header?.headerForm?.[0]?.block.components[0] || {};

  return (
    <LinkLabel
      onClick={() =>
        open(<FormRenderer form={form} formGuid={formGuid} hideModal={hide} />)
      }
      {...rest}
    >
      {label}
    </LinkLabel>
  );
};

// JSX

export const Header: FC<HeaderProps> = () => {
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    // Set the HTML and body's overflow and height properties based on navOpen
    const html = document.documentElement;
    const { body } = document;

    if (navOpen) {
      html.style.overflow = 'hidden';
      html.style.height = '100vh';
      body.style.overflow = 'hidden';
      body.style.height = '100vh';
    } else {
      // Restore the HTML and body's overflow and height properties when navOpen is false
      html.style.overflow = 'auto';
      html.style.height = 'auto';
      body.style.overflow = 'auto';
      body.style.height = 'auto';
    }

    // Cleanup: Restore the HTML and body's overflow and height properties on component unmount
    return () => {
      html.style.overflow = 'auto';
      html.style.height = 'auto';
      body.style.overflow = 'auto';
      body.style.height = 'auto';
    };
  }, [navOpen]);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      navigation: umbracoHeaderType {
        navigation {
          block {
            components {
              ... on UMBRACO_NavigationComponentType {
                link {
                  linkName
                  url
                }
                childNavigation {
                  link {
                    linkName
                    url
                  }
                }
              }
            }
          }
        }
      }
      header: umbracoHeaderType {
        id
        callToActionText
        headerForm {
          block {
            components {
              name
              ... on UMBRACO_FormComponentType {
                ...Form
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledTopBarContainer>
          <StyledLogo to="/" title="trade direct insurance">
            <Logo />
          </StyledLogo>
          <StyledButtons>
            <StyledMenuButton
              variant="primaryInverse"
              iconRight={<Menu size="small" />}
              a11yTitle="Open menu"
              onClick={() => setNavOpen(true)}
              small
            />
          </StyledButtons>
        </StyledTopBarContainer>

        <Navigation
          isActive={navOpen}
          items={data?.navigation?.navigation?.[0].block?.components}
          setNavOpen={setNavOpen}
        />
      </StyledContainer>

      <StyledPrimaryActionBar>
        <StyledActionContainer>
          {data?.header?.callToActionText && <div>{createMarkup(data.header.callToActionText)}</div>}
          <div>
            <StyledLinkLabelTel
              url="tel:0800 0280 380"
              label="0800 0280 380"
              inverse
            />
            {` or `}
            <CallBackLink label=" arrange a call-back" inverse data={data} />
          </div>
        </StyledActionContainer>
      </StyledPrimaryActionBar>
    </StyledHeader>
  );
};

export default Header;
